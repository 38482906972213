<template>
  <div>
    <nav class="px-3">
      <div class="container-fluid d-flex justify-content-between flex-column flex-md-row">
        <div class="wrapper row m-0">
          <div @click="goHome()" class="home nav-btn col-auto mb-2 me-3">
            Federico Barbon
          </div>
          <div :style="[openAbout ? 'background:#0b24fb;color:#fff' : '']" @click="openAbout = !openAbout"
            class="studio-light nav-btn col-auto mb-2 me-3">
            Studio
          </div>
          <div class="gd nav-btn col-auto mb-2">
            <a target="_blank" href="https://en.wikipedia.org/wiki/Graphic_design">Graphic Design and Art Direction</a>
          </div>
        </div>

        <router-link v-if="!onArchive" to="/archive"
          class="archive nav-btn d-flex justify-content-center align-items-center d-inline">
          <div class="dot"></div>
          <div class="dot mx-1"></div>
          <div class="dot"></div>
        </router-link>
        <router-link v-else to="/" class="archive-rtn nav-btn d-flex justify-content-center align-items-center">
          <span>Close</span>
        </router-link>
      </div>
    </nav>
    <footer>
      <div class="static-bottom">
        <div style="height: 2rem" class="container">
          <div class="row d-flex align-items-center align-items-center justify-content-between h-100">
            <div class="col-md-3 col-auto">
              <a href="mailto:mail@federicobarbon.com">→ Subscribe</a>
            </div>
            <div class="col-md-3 col-auto instagram p-0 m-0">
              <a target="_blank" href="https://www.instagram.com/federico.barbon/">Instagram</a>
            </div>
            <div class="col-5 col-auto text-end ms-0 ps-0">
              © {{ new Date().getFullYear() }} Federico Barbon
            </div>
          </div>
        </div>
      </div>
      <div :class="[openAbout ? 'about-open' : '']" class="full-bio-container">
        <div class="container-fluid row pt-3 pe-0">
          <!-- Column one -->
          <div class="col-md-4 col-12 position-relative">
            <div @click="openAbout = false" class="close-btn d-flex d-md-none justify-content-center">
              <span>Close</span>
            </div>
            <div class="divider"></div>
            <div class="row">
              <div v-if="isPortrait" class="col-12 portrait-image mb-3">
                <img class="img-fluid" :src="portraitImage.url" alt="" />
              </div>
              <div class="col-12">
                <prismic-rich-text :field="aboutData.text_column_one" />
              </div>
            </div>
          </div>
          <!-- Column Two -->
          <div class="col-md-4 col-12">
            <div class="divider"></div>
            <prismic-rich-text :field="aboutData.text_column_two" />
          </div>
          <!-- Column three -->
          <div class="col-md-4 col-12 position-relative">
            <div @click="openAbout = false" class="close-btn d-none d-md-flex justify-content-center">
              <span>Close</span>
            </div>
            <div class="divider"></div>
            <prismic-rich-text :field="aboutData.text_column_three" />

            <div v-show="aboutData.enable_shop" class="d-flex justify-content-between pb-2">

              <div @click="shopOpen = !shopOpen" class="col-auto">↪ <span class="shop-button">Shop</span></div>
              <div @click="shopOpen = false" v-show="shopOpen" class="col-auto p-0 text-end close-btn-cross">✕</div>

            </div>
            <transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
              <div v-if="shopOpen">
                <div v-for="(product) in aboutData.shop_items" :key="product.product_name" class="row">
                  <div class="col-auto">

                    <img class="shop-image" :src="`${product.product_image.url}?&q=50&w=600`" alt="">
                  </div>
                  <div class="col-8">
                    <p class="mb-3"> {{ product.product_name }}</p>

                    <prismic-rich-text class="shop-text" :field="product.product_text" />

                  </div>
                  <div class="col-12 py-3">
                    <a target="_blank"
                      :href="`mailto:federicobarbon@gmail.com?subject=Request to buy from federicobarbon.com&body=Dear Federico,%0D%0A%0D%0AI would like to purchase a copy of ${product.product_name}.%0D%0A%0D%0AMy shipping address is...`"
                      class="buy-btn d-flex justify-content-center">
                      <span>Buy</span>
                    </a>
                  </div>
                </div>

              </div>
            </transition>
            <prismic-rich-text v-if="!shopOpen" :field="aboutData.column_three_imprint" />

          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openAbout: false,
      shopOpen: false,
      aboutData: [],
      portraitImage: [],
      isPortrait: true,
      onArchive: false,
      backgroundLight: true,
    };
  },
  methods: {
    getData() {
      this.$prismic.client.getSingle("about_page").then((response) => {
        this.aboutData = response.data;
        console.log(this.aboutData)
        if (response.data.portrait.url) {
          this.isPortrait = true;
        } else {
          this.isPortrait = false;
        }
        this.portraitImage = response.data.portrait;
      });
    },
    goHome() {
      if (this.$route.name !== "home") {
        this.$router.push("/");
      } else {
        document.getElementById("home-gallery").scrollTo(0, 0);
      }
    },
    checkRoute() {
      if (this.$route.path.length > 3) {
        this.onArchive = true;
      } else {
        this.onArchive = false;
      }
    },
    getImageLightness(imageSrc, callback) {
      let img = document.createElement("img");
      img.src = imageSrc;
      img.style.display = "none";
      img.crossOrigin = "Anonymous";
      document.body.appendChild(img);

      let colorSum = 0;

      img.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;

        let ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0);

        let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let data = imageData.data;
        let r, g, b, avg;

        for (let x = 0, len = data.length; x < len; x += 4) {
          r = data[x];
          g = data[x + 1];
          b = data[x + 2];

          avg = Math.floor((r + g + b) / 3);
          colorSum += avg;
        }

        let brightness = Math.floor(colorSum / (this.width * this.height));
        callback(brightness);
      };
    },
    observe() {
      this.faders = document.querySelectorAll("img");

      const appearOnScroll = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              console.log();
              this.getImageLightness(entry.target.src, (brightness) => {
                if (brightness > 150) {
                  this.backgroundLight = false;
                } else {
                  this.backgroundLight = true;
                }
              });
            }
          });
        },
        {
          rootMargin: `0% 50% 50% 0%`,
        }
      );
      this.faders.forEach((fader) => {
        appearOnScroll.observe(fader);
      });
    },
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
  created() {
    this.getData();
    this.checkRoute();
  },
  // mounted() {
  //   setTimeout(() => {
  //     this.observe();
  //   }, 1000);
  // },
  watch: {
    $route() {
      this.checkRoute();
    },
    openAbout() {
      if (this.openAbout) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.expand-enter-active {
  transition: all 200ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}

.close-btn-cross:hover {
  cursor: pointer;
}

.shop-image {
  width: 5.5rem;
  height: auto;
}

.shop-button {
  text-decoration: underline;
  user-select: none;
  transition: all ease 150ms;
}

.shop-button:hover {
  cursor: pointer;
  color: #0b24fb;
  letter-spacing: 0.4rem !important;
}


nav {
  width: 100%;
  position: relative;
  top: 1.5rem;
  left: 0;
  right: 0;
  z-index: 5;
}

@media (min-width: 768px) {
  nav {
    position: fixed;
  }
}

.nav-btn {
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
  padding: 0.25rem 1.5rem 0.25rem 1.5rem;
  // border: 1px solid black;
  border-radius: 20px;
  user-select: none;
  white-space: nowrap;
  width: auto;
}

.nav-btn:hover {
  cursor: pointer;
}

.home {
  background: #f0f0f0;
}

.home:hover {
  background: #0b24fb;
  color: #f0f0f0;
}

.studio-dark {
  background: #000;
  color: white;
}

.studio-light {
  background: #f0f0f0;
  color: black;
}

.studio-light:hover {
  background: #0b24fb;
  color: #f0f0f0;
}

.gd {
  background: #f0f0f0;
}

.gd a {
  text-decoration: none;
  color: black;
}

.gd:hover a {
  color: #f0f0f0;
}

.gd:hover {
  background: #0b24fb;
}

.dot {
  width: 9px;
  height: 9px;
  background: #000;
  border-radius: 50%;
  transition: background 50ms ease;
}

.archive {
  padding: 0.25rem !important;
  background: #f0f0f0;
  width: 6.6rem;
  height: 2.04rem;
}

.archive-rtn {
  padding: 0.25rem !important;
  background: #f0f0f0;
  width: 6.6rem;
  height: 2.04rem;
}

.archive-rtn {
  text-decoration: none;
  color: black;
}

.archive-rtn span {
  transition: all 150ms ease;
}

.archive-rtn:hover span {
  color: #0b24fb;
  letter-spacing: 0.5rem !important;
  margin-left: 0.5rem;
}

.archive:hover .dot {
  background: #0b24fb;
}

.close-btn {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  border-radius: 20px;
  background: white;
  box-shadow: 2px 2px 3px #000;
  padding: 0.25rem;
  width: 6rem;
}

.buy-btn {
  border-radius: 20px;
  background: white;
  box-shadow: 2px 2px 3px #000;
  padding: 0.25rem;
  width: 5.5rem;
}

.close-btn span,
.buy-btn span {
  width: 5rem;
  text-align: center;
  transition: all ease 150ms;
}

.close-btn:hover,
.buy-btn:hover {
  cursor: pointer;
  user-select: none;
}

.close-btn:hover span,
.buy-btn:hover span {
  color: #0b24fb;
  margin-left: 0.5rem;
  letter-spacing: 0.5rem !important;
}

.portrait-image {
  max-width: 8rem;
}

.first-col {
  height: 100%;
}

.divider {
  border-top: 4px solid black;
  width: 100%;
  margin-bottom: 0.3rem;
}

.about-open {
  bottom: 1.95rem !important;
}

.full-bio-container {
  transition: bottom 300ms ease;
  // transform: translateY(100%);
  line-height: 0.8rem;
  position: fixed;
  width: 100%;
  height: calc(100% - 1.95rem);
  overflow: scroll;
  bottom: -100%;

  background: #d9d9d9;
  z-index: 10;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.full-bio-container::-webkit-scrollbar {
  display: none;
}

@media (min-width: 768px) {
  .full-bio-container {
    height: auto;
    max-height: calc(100% - 2rem);
  }
}

footer {
  height: 2rem;
  width: 100%;
  bottom: 0;
  font-size: 0.7rem;
  background: #d9d9d9;
  position: fixed;
  z-index: 5;
}

footer a {
  text-decoration: none;
  color: black;
}

.static-bottom {
  z-index: 3;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2rem;
  background: #d9d9d9;
  width: 100%;
}

.instagram {
  text-decoration: none;
  text-transform: uppercase;
}

.instagram a {
  text-decoration: none;
  color: #000;
}
</style>