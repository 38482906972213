import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/bootstrap.min.css'

import { createPrismic } from '@prismicio/vue'

const prismic = createPrismic({
  endpoint: 'federicobarbon'
})

const app = createApp(App)
app.use(prismic)
app.use(router)
app.mount('#app')
