<template>
  <TheNav />
  <router-view v-slot="{ Component }">
    <transition name="route">
      <component :is="Component" />
    </transition>
  </router-view>
</template>
<script>
import TheNav from "@/components/ui/TheNav.vue";
export default {
  components: {
    TheNav,
  },
};
</script>
<style lang="scss">
.shop-text p:last-child {
  margin-bottom:0;
}

.full-bio-container a {
  text-decoration: none;
  color: black;
}

@font-face {
  font-family: "FTAktual-Bold";
  src: url("~@/assets/fonts/FTAktual-Bold.woff2") format("woff2"),
    url("~@/assets/fonts/FTAktual-Bold.woff") format("woff");
}
@font-face {
  font-family: "FTAktual-BoldItalic";
  src: url("~@/assets/fonts/FTAktual-BoldItalic.woff2") format("woff2"),
    url("~@/assets/fonts/FTAktual-BoldItalic.woff") format("woff");
}
#app {
  font-family: FTAktual-Bold, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  color: #000;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

html {
  height: 100%;
  min-height: 100%;
}
span.underline {
  text-decoration: underline;
}
.caption-text p {
  margin-bottom: 0;
}
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}
.route-enter-from,
.route-leave-to {
  opacity: 0;
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
}
</style>
