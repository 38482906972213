<template>
  <div
    id="home-gallery"
    class="project-content d-flex flex-md-row flex-column mx-4 mx-md-0 mb-5 mb-md-0"
  >
    <div class="mobile-padding d-block d-md-none"></div>

    <div
      :id="`project${index}`"
      v-for="(image, index) in projectsData"
      :key="index"
      class="col-auto me-md-4 me-0 image-wrapper position-relative"
      :class="{ 'squish-img': index === currentIndex, 'ms-md-4': index === currentIndex && index === 0 }"
    >
      <div
        @mouseover="speed = 1"
        @mouseleave="speed = 2"
        class="caption-divider"
      ></div>
      <img
        :key="index"
        :data-src="`${image.image.url}?&q=0`"
        class="lazyload img-fluid col-12 m-0 p-0 d-flex"
        :height="image.image.dimensions.height"
        :width="image.image.dimensions.width"
      />
      <div
        @mouseover="speed = 1"
        @mouseleave="speed = 2"
        v-show="index !== currentIndex"
        @click="showCaption(index)"
        class="d-none caption-btn d-md-flex justify-content-center align-items-center"
        :style="[
          index === currentIndex ? 'opacity:0;cursor:default!important;' : '',
        ]"
      >
        <span>Caption</span>
      </div>
      <div class="col-12 row caption m-0">
        <div class="col-10 m-0 p-0">
          <prismic-rich-text
            class="mb-0 pb-0 caption-text"
            :field="image.project_caption"
          />
          <p>Year: {{ image.project_year }}</p>
        </div>
        <div
          @click="showCaption(index)"
          class="d-none d-md-block col-2 close-caption text-end m-0 p-0"
        >
          —
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "lazysizes";
export default {
  name: "HelloWorld",
  data() {
    return {
      speed: 2,
      projectsData: [],
      currentIndex: null,
      currentCaption: null,
      paused: false,
      scrollerID: null,
    };
  },
  methods: {
    getData() {
      this.$prismic.client
        .getAllByType("project", {
          orderings: {
            field: "my.project.project_publication_date",
            direction: "desc",
          },
        })
        .then((response) => {
   
          // Declare an empty array to store the items
          this.projectsData = [];

          // Iterate through each object in the input array
          for (let i = 0; i < response.length; i++) {
            // Iterate through each item in the current object's array
            for (let j = 0; j < response[i].data.project_images.length; j++) {
              // Push the current item into the flattened array
              this.projectsData.push({
                ...response[i].data.project_images[j],
                project_caption: response[i].data.project_caption,
                project_year: response[i].data.project_publication_date.slice(0, 4),
              });
            }
          }
        });
    },
    showCaption(el) {
      if (el !== this.currentIndex || this.currentIndex === null) {
        this.currentIndex = el;
        this.stopScroll();
        setTimeout(() => {
          document.getElementById(`project${el}`).scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }, 300);
      } else {
        this.currentIndex = null;
        this.startScroll();
      }
    },

    scroll() {
      let id = setInterval(() => {
        document.getElementById("home-gallery").scrollBy(this.speed, 0);
      }, this.speed * 10);
      return id;
    },
    startScroll() {
      this.scrollerID = this.scroll();
      this.paused = false;
    },
    stopScroll() {
      this.paused = true;
      clearInterval(this.scrollerID);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (window.innerWidth > 768) {
        setTimeout(() => {
          this.startScroll();
        }, 300);
      }
    });
  },
  unmounted() {
    clearInterval(this.scrollerID);
  },
  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.mobile-padding {
  height: 4rem;
}

.projects-container::-webkit-scrollbar {
  display: none;
}

.project-content img {
  min-width: 100%;
  height: auto;
  width: auto;
  z-index: 1;
}
.project-content {
  width: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  display: inline-block;
  max-height: auto;
  position: relative;
  height: 100%;
}

.projects-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.caption {
  font-size: 0.8rem;
  width: 0;
  min-width: 100%;
  height: auto;
  z-index: 0;
  padding-top: 1rem !important;
}

@media (min-width: 768px) {
  .caption {
    font-size: 1.1rem;
    height: 8rem;
  }
  .projects-container {
    overflow-y: hidden;
  }
  .project-content {
    width: 100%;
    overflow-y: hidden;
    max-height: calc(100vh - 2rem);
  }
  .project-content img {
    height: calc(100vh - 2rem);
  }
}

.close-caption:hover {
  cursor: pointer;
}
.close-caption {
  user-select: none;
}

.caption-btn {
  // height: 2.04rem;
   box-shadow: 2px 2px 3px #000;
  padding: 0.25rem;
  border-radius: 20px;
  user-select: none;
  background: #f0f0f0;
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  opacity: 0;
  transition: opacity ease 100ms;
  font-size: 0.7rem;
  width: 6.5rem;
  text-align: center;
}
.caption-btn span {
  width: 8rem;
  transition: all ease 150ms;
}
.caption-btn:hover {
  opacity: 1;
  cursor: pointer;
  color: #0b24fb;
}
.caption-btn:hover span {
  margin-left: 0.5rem;
  letter-spacing: 0.4rem !important;
}
.caption-divider {
  position: absolute;
  width: 100%;
  height: 30%;
  bottom: 0;
}
.caption-divider:hover ~ .caption-btn {
  opacity: 1;
}
.slideshow-speed-banner {
  position: fixed;
  width: 100%;
  height: 30%;
  bottom: 0;
  z-index: 2;
}

.squish-img {
  transform: scale(0.8);
  // max-height: 70vh;
}
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

.image-wrapper {
  transition: transform ease 300ms, margin ease 200ms;
display:inline-block;
  transform-origin: top left;
  position: relative;
  
}

// .project-content div {
//   scroll-snap-align: start;
// }

.project-content::-webkit-scrollbar {
  display: none;
}
</style>
